.ContentEditable__root {
  border: 0;
  font-size: 15px;
  display: block;
  position: relative;
  tab-size: 1;
  outline: 0;
  /* padding: 8px 28px 40px; */
}
@media (max-width: 1025px) {
  .ContentEditable__root {
    padding-left: 8px;
    padding-right: 8px;
  }
}
